import { LocalizedString, ColorString, Branding, Configuration, ModalPublishConfiguration, ValidationFieldNamesConfiguration, ValidationSectionConfiguration, ValidationFieldNames, LanguageSwitchConfiguration, ActionbarConfiguration, SidebarConfiguration, ProfilePageConfiguration, UploadSectionConfiguration, VideoSectionConfiguration, BasicInfoSectionConfiguration, DescriptionSectionConfiguration, KeywordsSectionConfiguration, SocialMediaSectionConfiguration, iFrameSectionConfiguration, ConsentContentSectionConfiguration, LinksPageConfiguration, LinkSectionConfiguration, CategoriesPageConfiguration, LicencesPageConfiguration, ProductsPageConfiguration, ProductSectionConfiguration, TrademarksPageConfiguration, TrademarkSectionConfiguration, TeamPageConfiguration, PersonSectionConfiguration, MultimediaPageConfiguration, MultimediaImageSectionConfiguration, NewsPageConfiguration, NewsSectionConfiguration, EventDatesPageConfiguration, EventDatesSectionConfiguration, ShowroomsPageConfiguration, ShowroomsSectionConfiguration, CouponsPageConfiguration, CouponsSectionConfiguration, JobsPageConfiguration, JobsSectionConfiguration, PrintPageConfiguration, LogoBackgroundImageSectionConfiguration, CompanyNameSectionConfiguration, CompanySortSectionConfiguration, AddressPrintCatalogueSectionConfiguration, ContactInformationSectionConfiguration, CompanyDescriptionSectionConfiguration, AdsSectionConfiguration, EventShopPageConfiguration, ValidationMessagesConfiguration, ErrorMessagesConfiguration, CategoriesCountableConfiguration } from "./branding/branding";
import { Lang } from "./branding/BrandingGenerator";

export class BrandingData {
    protected lang: Lang;
    protected jsonData: any;
    protected subBrandings: BrandingData[] = [];

    constructor(jsonData: any, lang: Lang) {
        this.jsonData = jsonData
        this.lang = lang
    }

    setLang(lang: Lang) {
        this.lang = lang
        this.subBrandings.forEach(subBranding => subBranding.setLang(lang))
    }
}

export class BrandingImpl extends BrandingData implements Branding {
    _configuration!: Configuration;
    _pageTitle!: string;
    _availableLanguages!: string[];
    _loaderBgColor!: string;
    _loaderSpinColor!: ColorString;

    get configuration() {
        if (!this._configuration) {
            this._configuration = new ConfigurationImpl(this.jsonData["configuration"], this.lang)
            this.subBrandings.push(this._configuration as any as BrandingData)
        }
        return this._configuration
    }

    get pageTitle() {
        return this.jsonData["pageTitle"]
    }

    get availableLanguages() {
        return this.jsonData["availableLanguages"]
    }

    get loaderBgColor() {
        return this.jsonData["loaderBgColor"]
    }

    get loaderSpinColor() {
        return this.jsonData["loaderSpinColor"]
    }
}

class ConfigurationImpl extends BrandingData implements Configuration {
    _topicName!: string;
    _sotName!: string;
    _sotAccessToken!: string;
    _ogTitle!: LocalizedString;
    _ogDescription!: LocalizedString;
    _maincolor!: ColorString;
    _dateFormat!: string;
    _timeFormat!: string;
    _showCategoriesCode!: boolean;
    _showLicencesCode!: boolean;
    _hntSearch!: LocalizedString;
    _hntChips!: LocalizedString;
    _lblCommingSoon!: LocalizedString;
    _lblEmptySelect!: LocalizedString;
    _pageCounter!: LocalizedString;
    _pageCounterMiddle!: LocalizedString;
    _modalPublish!: ModalPublishConfiguration;
    _languageSwitch!: LanguageSwitchConfiguration;
    _actionbar!: ActionbarConfiguration;
    _sidebar!: SidebarConfiguration;
    _profilePage!: ProfilePageConfiguration;
    _linksPage!: LinksPageConfiguration;
    _categoriesPage!: CategoriesPageConfiguration;
    _licencesPage!: LicencesPageConfiguration;
    _productsPage!: ProductsPageConfiguration;
    _trademarksPage!: TrademarksPageConfiguration;
    _teamPage!: TeamPageConfiguration;
    _multimediaPage!: MultimediaPageConfiguration;
    _newsPage!: NewsPageConfiguration;
    _eventDatesPage!: EventDatesPageConfiguration;
    _showroomsPage!: ShowroomsPageConfiguration;
    _couponsPage!: CouponsPageConfiguration;
    _jobsPage!: JobsPageConfiguration;
    _printPage!: PrintPageConfiguration;
    _eventShopPage!: EventShopPageConfiguration;
    _validationMessages!: ValidationMessagesConfiguration;
    _errorMessages!: ErrorMessagesConfiguration;
    _monoLingual!: boolean;
    _countableCategories!: CategoriesCountableConfiguration;

    get topicName() {
        return this.jsonData["topicName"]
    }

    get sotName() {
        return this.jsonData["sotName"]
    }

    get sotAccessToken() {
        return this.jsonData["sotAccessToken"]
    }

    get ogTitle() {
        return this.jsonData["ogTitle"][this.lang]
    }

    get ogDescription() {
        return this.jsonData["ogDescription"][this.lang]
    }

    get maincolor() {
        return this.jsonData["maincolor"]
    }

    get dateFormat() {
        return this.jsonData["dateFormat"]
    }

    get timeFormat() {
        return this.jsonData["timeFormat"]
    }

    get showCategoriesCode() {
        return this.jsonData["showCategoriesCode"]
    }

    get showLicencesCode() {
        return this.jsonData["showLicencesCode"]
    }

    get hntSearch() {
        return this.jsonData["hntSearch"][this.lang]
    }

    get hntChips() {
        return this.jsonData["hntChips"][this.lang]
    }

    get lblCommingSoon() {
        return this.jsonData["lblCommingSoon"][this.lang]
    }

    get lblEmptySelect() {
        return this.jsonData["lblEmptySelect"][this.lang]
    }

    get pageCounter() {
        return this.jsonData["pageCounter"][this.lang]
    }

    get pageCounterMiddle() {
        return this.jsonData["pageCounterMiddle"][this.lang]
    }

    get modalPublish() {
        if (!this._modalPublish) {
            this._modalPublish = new ModalPublishConfigurationImpl(this.jsonData["modalPublish"], this.lang)
            this.subBrandings.push(this._modalPublish as any as BrandingData)
        }
        return this._modalPublish
    }

    get languageSwitch() {
        if (!this._languageSwitch) {
            this._languageSwitch = new LanguageSwitchConfigurationImpl(this.jsonData["languageSwitch"], this.lang)
            this.subBrandings.push(this._languageSwitch as any as BrandingData)
        }
        return this._languageSwitch
    }

    get actionbar() {
        if (!this._actionbar) {
            this._actionbar = new ActionbarConfigurationImpl(this.jsonData["actionbar"], this.lang)
            this.subBrandings.push(this._actionbar as any as BrandingData)
        }
        return this._actionbar
    }

    get sidebar() {
        if (!this._sidebar) {
            this._sidebar = new SidebarConfigurationImpl(this.jsonData["sidebar"], this.lang)
            this.subBrandings.push(this._sidebar as any as BrandingData)
        }
        return this._sidebar
    }

    get profilePage() {
        if (!this._profilePage) {
            this._profilePage = new ProfilePageConfigurationImpl(this.jsonData["profilePage"], this.lang)
            this.subBrandings.push(this._profilePage as any as BrandingData)
        }
        return this._profilePage
    }

    get linksPage() {
        if (!this._linksPage) {
            this._linksPage = new LinksPageConfigurationImpl(this.jsonData["linksPage"], this.lang)
            this.subBrandings.push(this._linksPage as any as BrandingData)
        }
        return this._linksPage
    }

    get categoriesPage() {
        if (!this._categoriesPage) {
            this._categoriesPage = new CategoriesPageConfigurationImpl(this.jsonData["categoriesPage"], this.lang)
            this.subBrandings.push(this._categoriesPage as any as BrandingData)
        }
        return this._categoriesPage
    }

    get licencesPage() {
        if (!this._licencesPage) {
            this._licencesPage = new LicencesPageConfigurationImpl(this.jsonData["licencesPage"], this.lang)
            this.subBrandings.push(this._licencesPage as any as BrandingData)
        }
        return this._licencesPage
    }

    get productsPage() {
        if (!this._productsPage) {
            this._productsPage = new ProductsPageConfigurationImpl(this.jsonData["productsPage"], this.lang)
            this.subBrandings.push(this._productsPage as any as BrandingData)
        }
        return this._productsPage
    }

    get trademarksPage() {
        if (!this._trademarksPage) {
            this._trademarksPage = new TrademarksPageConfigurationImpl(this.jsonData["trademarksPage"], this.lang)
            this.subBrandings.push(this._trademarksPage as any as BrandingData)
        }
        return this._trademarksPage
    }

    get teamPage() {
        if (!this._teamPage) {
            this._teamPage = new TeamPageConfigurationImpl(this.jsonData["teamPage"], this.lang)
            this.subBrandings.push(this._teamPage as any as BrandingData)
        }
        return this._teamPage
    }

    get multimediaPage() {
        if (!this._multimediaPage) {
            this._multimediaPage = new MultimediaPageConfigurationImpl(this.jsonData["multimediaPage"], this.lang)
            this.subBrandings.push(this._multimediaPage as any as BrandingData)
        }
        return this._multimediaPage
    }

    get newsPage() {
        if (!this._newsPage) {
            this._newsPage = new NewsPageConfigurationImpl(this.jsonData["newsPage"], this.lang)
            this.subBrandings.push(this._newsPage as any as BrandingData)
        }
        return this._newsPage
    }

    get eventDatesPage() {
        if (!this._eventDatesPage) {
            this._eventDatesPage = new EventDatesPageConfigurationImpl(this.jsonData["eventDatesPage"], this.lang)
            this.subBrandings.push(this._eventDatesPage as any as BrandingData)
        }
        return this._eventDatesPage
    }

    get showroomsPage() {
        if (!this._showroomsPage) {
            this._showroomsPage = new ShowroomsPageConfigurationImpl(this.jsonData["showroomsPage"], this.lang)
            this.subBrandings.push(this._showroomsPage as any as BrandingData)
        }
        return this._showroomsPage
    }

    get couponsPage() {
        if (!this._couponsPage) {
            this._couponsPage = new CouponsPageConfigurationImpl(this.jsonData["couponsPage"], this.lang)
            this.subBrandings.push(this._couponsPage as any as BrandingData)
        }
        return this._couponsPage
    }

    get jobsPage() {
        if (!this._jobsPage) {
            this._jobsPage = new JobsPageConfigurationImpl(this.jsonData["jobsPage"], this.lang)
            this.subBrandings.push(this._jobsPage as any as BrandingData)
        }
        return this._jobsPage
    }

    get printPage() {
        if (!this._printPage) {
            this._printPage = new PrintPageConfigurationImpl(this.jsonData["printPage"], this.lang)
            this.subBrandings.push(this._printPage as any as BrandingData)
        }
        return this._printPage
    }

    get eventShopPage() {
        if (!this._eventShopPage) {
            this._eventShopPage = new EventShopPageConfigurationImpl(this.jsonData["eventShopPage"], this.lang)
            this.subBrandings.push(this._eventShopPage as any as BrandingData)
        }
        return this._eventShopPage
    }

    get validationMessages() {
        if (!this._validationMessages) {
            this._validationMessages = new ValidationMessagesConfigurationImpl(this.jsonData["validationMessages"], this.lang)
            this.subBrandings.push(this._validationMessages as any as BrandingData)
        }
        return this._validationMessages
    }

    get errorMessages() {
        if (!this._errorMessages) {
            this._errorMessages = new ErrorMessagesConfigurationImpl(this.jsonData["errorMessages"], this.lang)
            this.subBrandings.push(this._errorMessages as any as BrandingData)
        }
        return this._errorMessages
    }

    get monoLingual() {
        return this.jsonData["monoLingual"]
    }

    get countableCategories() {
        if (!this._countableCategories) {
            this._countableCategories = new CategoriesCountableConfigurationImpl(this.jsonData["countableCategories"], this.lang)
            this.subBrandings.push(this._countableCategories as any as BrandingData)
        }
        return this._countableCategories
    }
}

class ModalPublishConfigurationImpl extends BrandingData implements ModalPublishConfiguration {
    _title!: LocalizedString;
    _label!: LocalizedString;
    _fieldNamesConfig!: ValidationFieldNamesConfiguration;

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get label() {
        return this.jsonData["label"][this.lang]
    }

    get fieldNamesConfig() {
        if (!this._fieldNamesConfig) {
            this._fieldNamesConfig = new ValidationFieldNamesConfigurationImpl(this.jsonData["fieldNamesConfig"], this.lang)
            this.subBrandings.push(this._fieldNamesConfig as any as BrandingData)
        }
        return this._fieldNamesConfig
    }
}

class ValidationFieldNamesConfigurationImpl extends BrandingData implements ValidationFieldNamesConfiguration {
    _organization!: ValidationSectionConfiguration;
    _links!: ValidationSectionConfiguration;
    _products!: ValidationSectionConfiguration;
    _trademarks!: ValidationSectionConfiguration;
    _persons!: ValidationSectionConfiguration;
    _medias!: ValidationSectionConfiguration;
    _newsList!: ValidationSectionConfiguration;
    _eventDates!: ValidationSectionConfiguration;
    _showrooms!: ValidationSectionConfiguration;
    _goodies!: ValidationSectionConfiguration;
    _jobOffers!: ValidationSectionConfiguration;
    _printCatalog!: ValidationSectionConfiguration;

    get organization() {
        if (!this._organization) {
            this._organization = new ValidationSectionConfigurationImpl(this.jsonData["organization"], this.lang)
            this.subBrandings.push(this._organization as any as BrandingData)
        }
        return this._organization
    }

    get links() {
        if (!this._links) {
            this._links = new ValidationSectionConfigurationImpl(this.jsonData["links"], this.lang)
            this.subBrandings.push(this._links as any as BrandingData)
        }
        return this._links
    }

    get products() {
        if (!this._products) {
            this._products = new ValidationSectionConfigurationImpl(this.jsonData["products"], this.lang)
            this.subBrandings.push(this._products as any as BrandingData)
        }
        return this._products
    }

    get trademarks() {
        if (!this._trademarks) {
            this._trademarks = new ValidationSectionConfigurationImpl(this.jsonData["trademarks"], this.lang)
            this.subBrandings.push(this._trademarks as any as BrandingData)
        }
        return this._trademarks
    }

    get persons() {
        if (!this._persons) {
            this._persons = new ValidationSectionConfigurationImpl(this.jsonData["persons"], this.lang)
            this.subBrandings.push(this._persons as any as BrandingData)
        }
        return this._persons
    }

    get medias() {
        if (!this._medias) {
            this._medias = new ValidationSectionConfigurationImpl(this.jsonData["medias"], this.lang)
            this.subBrandings.push(this._medias as any as BrandingData)
        }
        return this._medias
    }

    get newsList() {
        if (!this._newsList) {
            this._newsList = new ValidationSectionConfigurationImpl(this.jsonData["newsList"], this.lang)
            this.subBrandings.push(this._newsList as any as BrandingData)
        }
        return this._newsList
    }

    get eventDates() {
        if (!this._eventDates) {
            this._eventDates = new ValidationSectionConfigurationImpl(this.jsonData["eventDates"], this.lang)
            this.subBrandings.push(this._eventDates as any as BrandingData)
        }
        return this._eventDates
    }

    get showrooms() {
        if (!this._showrooms) {
            this._showrooms = new ValidationSectionConfigurationImpl(this.jsonData["showrooms"], this.lang)
            this.subBrandings.push(this._showrooms as any as BrandingData)
        }
        return this._showrooms
    }

    get goodies() {
        if (!this._goodies) {
            this._goodies = new ValidationSectionConfigurationImpl(this.jsonData["goodies"], this.lang)
            this.subBrandings.push(this._goodies as any as BrandingData)
        }
        return this._goodies
    }

    get jobOffers() {
        if (!this._jobOffers) {
            this._jobOffers = new ValidationSectionConfigurationImpl(this.jsonData["jobOffers"], this.lang)
            this.subBrandings.push(this._jobOffers as any as BrandingData)
        }
        return this._jobOffers
    }

    get printCatalog() {
        if (!this._printCatalog) {
            this._printCatalog = new ValidationSectionConfigurationImpl(this.jsonData["printCatalog"], this.lang)
            this.subBrandings.push(this._printCatalog as any as BrandingData)
        }
        return this._printCatalog
    }
}

class ValidationSectionConfigurationImpl extends BrandingData implements ValidationSectionConfiguration {
    _name!: LocalizedString;
    _nameIndex!: LocalizedString;
    _fieldNames!: ValidationFieldNames[];

    get name() {
        return this.jsonData["name"][this.lang]
    }

    get nameIndex() {
        return this.jsonData["nameIndex"][this.lang]
    }

    get fieldNames() {
        if (!this._fieldNames) {
            this._fieldNames = []
            for (const arrayElement of this.jsonData["fieldNames"]) {
                const arrayObject = new ValidationFieldNamesImpl(arrayElement, this.lang)
                this.fieldNames.push(arrayObject)
                this.subBrandings.push(arrayObject as any as BrandingData)
            }
        }
        return this._fieldNames
    }
}

class ValidationFieldNamesImpl extends BrandingData implements ValidationFieldNames {
    _name!: string;
    _value!: LocalizedString;

    get name() {
        return this.jsonData["name"]
    }

    get value() {
        return this.jsonData["value"][this.lang]
    }
}

class LanguageSwitchConfigurationImpl extends BrandingData implements LanguageSwitchConfiguration {
    _lblEnglishOption!: LocalizedString;
    _lblGermanOption!: LocalizedString;

    get lblEnglishOption() {
        return this.jsonData["lblEnglishOption"][this.lang]
    }

    get lblGermanOption() {
        return this.jsonData["lblGermanOption"][this.lang]
    }
}

class ActionbarConfigurationImpl extends BrandingData implements ActionbarConfiguration {
    _btnPreview!: LocalizedString;
    _btnPublish!: LocalizedString;

    get btnPreview() {
        return this.jsonData["btnPreview"][this.lang]
    }

    get btnPublish() {
        return this.jsonData["btnPublish"][this.lang]
    }
}

class SidebarConfigurationImpl extends BrandingData implements SidebarConfiguration {
    _lblProfile!: LocalizedString;
    _lblCategories!: LocalizedString;
    _lblLicences!: LocalizedString;
    _lblLinks!: LocalizedString;
    _lblProducts!: LocalizedString;
    _lblTrademarks!: LocalizedString;
    _lblTeam!: LocalizedString;
    _lblMultimedia!: LocalizedString;
    _lblNews!: LocalizedString;
    _lblEventDate!: LocalizedString;
    _lblShowrooms!: LocalizedString;
    _lblCoupons!: LocalizedString;
    _lblJobs!: LocalizedString;
    _lblPrint!: LocalizedString;
    _lblEventShop!: LocalizedString;

    get lblProfile() {
        return this.jsonData["lblProfile"][this.lang]
    }

    get lblCategories() {
        return this.jsonData["lblCategories"][this.lang]
    }

    get lblLicences() {
        return this.jsonData["lblLicences"][this.lang]
    }

    get lblLinks() {
        return this.jsonData["lblLinks"][this.lang]
    }

    get lblProducts() {
        return this.jsonData["lblProducts"][this.lang]
    }

    get lblTrademarks() {
        return this.jsonData["lblTrademarks"][this.lang]
    }

    get lblTeam() {
        return this.jsonData["lblTeam"][this.lang]
    }

    get lblMultimedia() {
        return this.jsonData["lblMultimedia"][this.lang]
    }

    get lblNews() {
        return this.jsonData["lblNews"][this.lang]
    }

    get lblEventDate() {
        return this.jsonData["lblEventDate"][this.lang]
    }

    get lblShowrooms() {
        return this.jsonData["lblShowrooms"][this.lang]
    }

    get lblCoupons() {
        return this.jsonData["lblCoupons"][this.lang]
    }

    get lblJobs() {
        return this.jsonData["lblJobs"][this.lang]
    }

    get lblPrint() {
        return this.jsonData["lblPrint"][this.lang]
    }

    get lblEventShop() {
        return this.jsonData["lblEventShop"][this.lang]
    }
}

class ProfilePageConfigurationImpl extends BrandingData implements ProfilePageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _sctUpload!: UploadSectionConfiguration;
    _sctVideo!: VideoSectionConfiguration;
    _sctBasicInfo!: BasicInfoSectionConfiguration;
    _sctDescription!: DescriptionSectionConfiguration;
    _sctKeywords!: KeywordsSectionConfiguration;
    _sctSocialMedia!: SocialMediaSectionConfiguration;
    _sctiFrame!: iFrameSectionConfiguration;
    _sctConsentContent!: ConsentContentSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get sctUpload() {
        if (!this._sctUpload) {
            this._sctUpload = new UploadSectionConfigurationImpl(this.jsonData["sctUpload"], this.lang)
            this.subBrandings.push(this._sctUpload as any as BrandingData)
        }
        return this._sctUpload
    }

    get sctVideo() {
        if (!this._sctVideo) {
            this._sctVideo = new VideoSectionConfigurationImpl(this.jsonData["sctVideo"], this.lang)
            this.subBrandings.push(this._sctVideo as any as BrandingData)
        }
        return this._sctVideo
    }

    get sctBasicInfo() {
        if (!this._sctBasicInfo) {
            this._sctBasicInfo = new BasicInfoSectionConfigurationImpl(this.jsonData["sctBasicInfo"], this.lang)
            this.subBrandings.push(this._sctBasicInfo as any as BrandingData)
        }
        return this._sctBasicInfo
    }

    get sctDescription() {
        if (!this._sctDescription) {
            this._sctDescription = new DescriptionSectionConfigurationImpl(this.jsonData["sctDescription"], this.lang)
            this.subBrandings.push(this._sctDescription as any as BrandingData)
        }
        return this._sctDescription
    }

    get sctKeywords() {
        if (!this._sctKeywords) {
            this._sctKeywords = new KeywordsSectionConfigurationImpl(this.jsonData["sctKeywords"], this.lang)
            this.subBrandings.push(this._sctKeywords as any as BrandingData)
        }
        return this._sctKeywords
    }

    get sctSocialMedia() {
        if (!this._sctSocialMedia) {
            this._sctSocialMedia = new SocialMediaSectionConfigurationImpl(this.jsonData["sctSocialMedia"], this.lang)
            this.subBrandings.push(this._sctSocialMedia as any as BrandingData)
        }
        return this._sctSocialMedia
    }

    get sctiFrame() {
        if (!this._sctiFrame) {
            this._sctiFrame = new iFrameSectionConfigurationImpl(this.jsonData["sctiFrame"], this.lang)
            this.subBrandings.push(this._sctiFrame as any as BrandingData)
        }
        return this._sctiFrame
    }

    get sctConsentContent() {
        if (!this._sctConsentContent) {
            this._sctConsentContent = new ConsentContentSectionConfigurationImpl(this.jsonData["sctConsentContent"], this.lang)
            this.subBrandings.push(this._sctConsentContent as any as BrandingData)
        }
        return this._sctConsentContent
    }
}

class UploadSectionConfigurationImpl extends BrandingData implements UploadSectionConfiguration {
    _lblUpload!: LocalizedString;
    _lblCompanyLogo!: LocalizedString;
    _lblCompanyBackground!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntProp!: LocalizedString;
    _hntTitleBackground!: LocalizedString;
    _hntPropBackground!: LocalizedString;
    _btnBrowseFilesLogo!: LocalizedString;
    _btnDeleteImgLogo!: LocalizedString;
    _btnBrowseFilesBackground!: LocalizedString;
    _btnDeleteImgBackground!: LocalizedString;

    get lblUpload() {
        return this.jsonData["lblUpload"][this.lang]
    }

    get lblCompanyLogo() {
        return this.jsonData["lblCompanyLogo"][this.lang]
    }

    get lblCompanyBackground() {
        return this.jsonData["lblCompanyBackground"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntProp() {
        return this.jsonData["hntProp"][this.lang]
    }

    get hntTitleBackground() {
        return this.jsonData["hntTitleBackground"][this.lang]
    }

    get hntPropBackground() {
        return this.jsonData["hntPropBackground"][this.lang]
    }

    get btnBrowseFilesLogo() {
        return this.jsonData["btnBrowseFilesLogo"][this.lang]
    }

    get btnDeleteImgLogo() {
        return this.jsonData["btnDeleteImgLogo"][this.lang]
    }

    get btnBrowseFilesBackground() {
        return this.jsonData["btnBrowseFilesBackground"][this.lang]
    }

    get btnDeleteImgBackground() {
        return this.jsonData["btnDeleteImgBackground"][this.lang]
    }
}

class VideoSectionConfigurationImpl extends BrandingData implements VideoSectionConfiguration {
    _lblCompanyVideo!: LocalizedString;
    _lblThumbnail!: LocalizedString;
    _lblVideo!: LocalizedString;
    _hntTitleThumbnail!: LocalizedString;
    _hntPropThumbnail!: LocalizedString;
    _hntTitleVideo!: LocalizedString;
    _hntPropVideo!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;
    _btnDeleteVideo!: LocalizedString;

    get lblCompanyVideo() {
        return this.jsonData["lblCompanyVideo"][this.lang]
    }

    get lblThumbnail() {
        return this.jsonData["lblThumbnail"][this.lang]
    }

    get lblVideo() {
        return this.jsonData["lblVideo"][this.lang]
    }

    get hntTitleThumbnail() {
        return this.jsonData["hntTitleThumbnail"][this.lang]
    }

    get hntPropThumbnail() {
        return this.jsonData["hntPropThumbnail"][this.lang]
    }

    get hntTitleVideo() {
        return this.jsonData["hntTitleVideo"][this.lang]
    }

    get hntPropVideo() {
        return this.jsonData["hntPropVideo"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }

    get btnDeleteVideo() {
        return this.jsonData["btnDeleteVideo"][this.lang]
    }
}

class BasicInfoSectionConfigurationImpl extends BrandingData implements BasicInfoSectionConfiguration {
    _lblBasicInformation!: LocalizedString;
    _lblCompany!: LocalizedString;
    _hntCompany!: LocalizedString;
    _companyNameReadonly!: boolean;
    _lblSynonyms!: LocalizedString;
    _hntSynonyms!: LocalizedString;
    _lblIndustry!: LocalizedString;
    _hntIndustry!: LocalizedString;
    _lblPhoneNumber!: LocalizedString;
    _hntPhoneNumber!: LocalizedString;
    _lblMobile!: LocalizedString;
    _hntMobile!: LocalizedString;
    _lblFax!: LocalizedString;
    _hntFax!: LocalizedString;
    _lblEmail!: LocalizedString;
    _hntEmail!: LocalizedString;
    _lblEmail2!: LocalizedString;
    _hntEmail2!: LocalizedString;
    _lblWebsite!: LocalizedString;
    _hntWebsite!: LocalizedString;
    _lblAddress1!: LocalizedString;
    _hntAddress1!: LocalizedString;
    _lblAddress2!: LocalizedString;
    _hntAddress2!: LocalizedString;
    _lblAddress3!: LocalizedString;
    _hntAddress3!: LocalizedString;
    _lblZipCode!: LocalizedString;
    _hntZipCode!: LocalizedString;
    _lblCity!: LocalizedString;
    _hntCity!: LocalizedString;
    _lblCountry!: LocalizedString;
    _hntCountry!: LocalizedString;
    _lblCompanySize!: LocalizedString;
    _hntCompanySize!: LocalizedString;
    _lblFoundingYear!: LocalizedString;
    _hntFoundingYear!: LocalizedString;

    get lblBasicInformation() {
        return this.jsonData["lblBasicInformation"][this.lang]
    }

    get lblCompany() {
        return this.jsonData["lblCompany"][this.lang]
    }

    get hntCompany() {
        return this.jsonData["hntCompany"][this.lang]
    }

    get companyNameReadonly() {
        return this.jsonData["companyNameReadonly"]
    }

    get lblSynonyms() {
        return this.jsonData["lblSynonyms"][this.lang]
    }

    get hntSynonyms() {
        return this.jsonData["hntSynonyms"][this.lang]
    }

    get lblIndustry() {
        return this.jsonData["lblIndustry"][this.lang]
    }

    get hntIndustry() {
        return this.jsonData["hntIndustry"][this.lang]
    }

    get lblPhoneNumber() {
        return this.jsonData["lblPhoneNumber"][this.lang]
    }

    get hntPhoneNumber() {
        return this.jsonData["hntPhoneNumber"][this.lang]
    }

    get lblMobile() {
        return this.jsonData["lblMobile"][this.lang]
    }

    get hntMobile() {
        return this.jsonData["hntMobile"][this.lang]
    }

    get lblFax() {
        return this.jsonData["lblFax"][this.lang]
    }

    get hntFax() {
        return this.jsonData["hntFax"][this.lang]
    }

    get lblEmail() {
        return this.jsonData["lblEmail"][this.lang]
    }

    get hntEmail() {
        return this.jsonData["hntEmail"][this.lang]
    }

    get lblEmail2() {
        return this.jsonData["lblEmail2"][this.lang]
    }

    get hntEmail2() {
        return this.jsonData["hntEmail2"][this.lang]
    }

    get lblWebsite() {
        return this.jsonData["lblWebsite"][this.lang]
    }

    get hntWebsite() {
        return this.jsonData["hntWebsite"][this.lang]
    }

    get lblAddress1() {
        return this.jsonData["lblAddress1"][this.lang]
    }

    get hntAddress1() {
        return this.jsonData["hntAddress1"][this.lang]
    }

    get lblAddress2() {
        return this.jsonData["lblAddress2"][this.lang]
    }

    get hntAddress2() {
        return this.jsonData["hntAddress2"][this.lang]
    }

    get lblAddress3() {
        return this.jsonData["lblAddress3"][this.lang]
    }

    get hntAddress3() {
        return this.jsonData["hntAddress3"][this.lang]
    }

    get lblZipCode() {
        return this.jsonData["lblZipCode"][this.lang]
    }

    get hntZipCode() {
        return this.jsonData["hntZipCode"][this.lang]
    }

    get lblCity() {
        return this.jsonData["lblCity"][this.lang]
    }

    get hntCity() {
        return this.jsonData["hntCity"][this.lang]
    }

    get lblCountry() {
        return this.jsonData["lblCountry"][this.lang]
    }

    get hntCountry() {
        return this.jsonData["hntCountry"][this.lang]
    }

    get lblCompanySize() {
        return this.jsonData["lblCompanySize"][this.lang]
    }

    get hntCompanySize() {
        return this.jsonData["hntCompanySize"][this.lang]
    }

    get lblFoundingYear() {
        return this.jsonData["lblFoundingYear"][this.lang]
    }

    get hntFoundingYear() {
        return this.jsonData["hntFoundingYear"][this.lang]
    }
}

class DescriptionSectionConfigurationImpl extends BrandingData implements DescriptionSectionConfiguration {
    _lblDescription!: LocalizedString;
    _lblLanguage!: LocalizedString;
    _hntLanguage!: LocalizedString;
    _lblLongDescription!: LocalizedString;
    _hntLongDescription!: LocalizedString;
    _lblShortDescription!: LocalizedString;
    _hntShortDescription!: LocalizedString;
    _btnAddTranslation!: LocalizedString;

    get lblDescription() {
        return this.jsonData["lblDescription"][this.lang]
    }

    get lblLanguage() {
        return this.jsonData["lblLanguage"][this.lang]
    }

    get hntLanguage() {
        return this.jsonData["hntLanguage"][this.lang]
    }

    get lblLongDescription() {
        return this.jsonData["lblLongDescription"][this.lang]
    }

    get hntLongDescription() {
        return this.jsonData["hntLongDescription"][this.lang]
    }

    get lblShortDescription() {
        return this.jsonData["lblShortDescription"][this.lang]
    }

    get hntShortDescription() {
        return this.jsonData["hntShortDescription"][this.lang]
    }

    get btnAddTranslation() {
        return this.jsonData["btnAddTranslation"][this.lang]
    }
}

class KeywordsSectionConfigurationImpl extends BrandingData implements KeywordsSectionConfiguration {
    _title!: LocalizedString;
    _lblKeywords!: LocalizedString;
    _hntKeywords!: LocalizedString;

    get title() {
        return this.jsonData["title"][this.lang]
    }

    get lblKeywords() {
        return this.jsonData["lblKeywords"][this.lang]
    }

    get hntKeywords() {
        return this.jsonData["hntKeywords"][this.lang]
    }
}

class SocialMediaSectionConfigurationImpl extends BrandingData implements SocialMediaSectionConfiguration {
    _lblSocialMedia!: LocalizedString;
    _lblFacebook!: LocalizedString;
    _hntFacebook!: LocalizedString;
    _lblInstagram!: LocalizedString;
    _hntInstagram!: LocalizedString;
    _lblLinkedIn!: LocalizedString;
    _hntLinkedIn!: LocalizedString;
    _lblXing!: LocalizedString;
    _hntXing!: LocalizedString;
    _lblTwitter!: LocalizedString;
    _hntTwitter!: LocalizedString;
    _lblYouTube!: LocalizedString;
    _hntYouTube!: LocalizedString;
    _lblPinterest!: LocalizedString;
    _hntPinterest!: LocalizedString;

    get lblSocialMedia() {
        return this.jsonData["lblSocialMedia"][this.lang]
    }

    get lblFacebook() {
        return this.jsonData["lblFacebook"][this.lang]
    }

    get hntFacebook() {
        return this.jsonData["hntFacebook"][this.lang]
    }

    get lblInstagram() {
        return this.jsonData["lblInstagram"][this.lang]
    }

    get hntInstagram() {
        return this.jsonData["hntInstagram"][this.lang]
    }

    get lblLinkedIn() {
        return this.jsonData["lblLinkedIn"][this.lang]
    }

    get hntLinkedIn() {
        return this.jsonData["hntLinkedIn"][this.lang]
    }

    get lblXing() {
        return this.jsonData["lblXing"][this.lang]
    }

    get hntXing() {
        return this.jsonData["hntXing"][this.lang]
    }

    get lblTwitter() {
        return this.jsonData["lblTwitter"][this.lang]
    }

    get hntTwitter() {
        return this.jsonData["hntTwitter"][this.lang]
    }

    get lblYouTube() {
        return this.jsonData["lblYouTube"][this.lang]
    }

    get hntYouTube() {
        return this.jsonData["hntYouTube"][this.lang]
    }

    get lblPinterest() {
        return this.jsonData["lblPinterest"][this.lang]
    }

    get hntPinterest() {
        return this.jsonData["hntPinterest"][this.lang]
    }
}

class iFrameSectionConfigurationImpl extends BrandingData implements iFrameSectionConfiguration {
    _lbliFrame!: LocalizedString;
    _lbliFrameURL!: LocalizedString;
    _hntiFrameUR!: LocalizedString;
    _lbliFrameURLDE!: LocalizedString;
    _hntiFrameURLDE!: LocalizedString;

    get lbliFrame() {
        return this.jsonData["lbliFrame"][this.lang]
    }

    get lbliFrameURL() {
        return this.jsonData["lbliFrameURL"][this.lang]
    }

    get hntiFrameUR() {
        return this.jsonData["hntiFrameUR"][this.lang]
    }

    get lbliFrameURLDE() {
        return this.jsonData["lbliFrameURLDE"][this.lang]
    }

    get hntiFrameURLDE() {
        return this.jsonData["hntiFrameURLDE"][this.lang]
    }
}

class ConsentContentSectionConfigurationImpl extends BrandingData implements ConsentContentSectionConfiguration {
    _lblConsentContent!: LocalizedString;
    _lblLanguage!: LocalizedString;
    _lblConsentTitle!: LocalizedString;
    _hntConsentTitle!: LocalizedString;
    _lblConsentText!: LocalizedString;
    _hntConsentText!: LocalizedString;

    get lblConsentContent() {
        return this.jsonData["lblConsentContent"][this.lang]
    }

    get lblLanguage() {
        return this.jsonData["lblLanguage"][this.lang]
    }

    get lblConsentTitle() {
        return this.jsonData["lblConsentTitle"][this.lang]
    }

    get hntConsentTitle() {
        return this.jsonData["hntConsentTitle"][this.lang]
    }

    get lblConsentText() {
        return this.jsonData["lblConsentText"][this.lang]
    }

    get hntConsentText() {
        return this.jsonData["hntConsentText"][this.lang]
    }
}

class LinksPageConfigurationImpl extends BrandingData implements LinksPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _btnAddLink!: LocalizedString;
    _sctLink!: LinkSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get btnAddLink() {
        return this.jsonData["btnAddLink"][this.lang]
    }

    get sctLink() {
        if (!this._sctLink) {
            this._sctLink = new LinkSectionConfigurationImpl(this.jsonData["sctLink"], this.lang)
            this.subBrandings.push(this._sctLink as any as BrandingData)
        }
        return this._sctLink
    }
}

class LinkSectionConfigurationImpl extends BrandingData implements LinkSectionConfiguration {
    _titleEmptyLink!: LocalizedString;
    _lblURL!: LocalizedString;
    _hntURL!: LocalizedString;
    _lblText!: LocalizedString;
    _hntText!: LocalizedString;

    get titleEmptyLink() {
        return this.jsonData["titleEmptyLink"][this.lang]
    }

    get lblURL() {
        return this.jsonData["lblURL"][this.lang]
    }

    get hntURL() {
        return this.jsonData["hntURL"][this.lang]
    }

    get lblText() {
        return this.jsonData["lblText"][this.lang]
    }

    get hntText() {
        return this.jsonData["hntText"][this.lang]
    }
}

class CategoriesPageConfigurationImpl extends BrandingData implements CategoriesPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _categoryWhitelist!: string[];

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get categoryWhitelist() {
        return this.jsonData["categoryWhitelist"]
    }
}

class LicencesPageConfigurationImpl extends BrandingData implements LicencesPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _categoryWhitelist!: string[];
    _licencesPageVisible!: boolean;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get categoryWhitelist() {
        return this.jsonData["categoryWhitelist"]
    }

    get licencesPageVisible() {
        return this.jsonData["licencesPageVisible"]
    }
}

class ProductsPageConfigurationImpl extends BrandingData implements ProductsPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _btnAddProduct!: LocalizedString;
    _sctProduct!: ProductSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get btnAddProduct() {
        return this.jsonData["btnAddProduct"][this.lang]
    }

    get sctProduct() {
        if (!this._sctProduct) {
            this._sctProduct = new ProductSectionConfigurationImpl(this.jsonData["sctProduct"], this.lang)
            this.subBrandings.push(this._sctProduct as any as BrandingData)
        }
        return this._sctProduct
    }
}

class ProductSectionConfigurationImpl extends BrandingData implements ProductSectionConfiguration {
    _titleEmptyProduct!: LocalizedString;
    _lblProductImage!: LocalizedString;
    _lblProductImageMessage!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntProp!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;
    _lblProductname!: LocalizedString;
    _hntProductname!: LocalizedString;
    _lblProductPublicationStart!: LocalizedString;
    _hntProductPublicationStart!: LocalizedString;
    _lblProductPublicationEnd!: LocalizedString;
    _hntProductPublicationEnd!: LocalizedString;
    _lblDescriptionDetail!: LocalizedString;
    _hntDescriptionDetail!: LocalizedString;
    _lblDescriptionShort!: LocalizedString;
    _hntDescriptionShort!: LocalizedString;
    _lblTabCategories!: LocalizedString;
    _lblTabMultimedia!: LocalizedString;
    _lblTabKeywords!: LocalizedString;
    _lblCategoriesDescription!: LocalizedString;
    _lblMultimediaDescription!: LocalizedString;
    _categoryWhitelist!: string[];
    _multimediaLimit!: number;

    get titleEmptyProduct() {
        return this.jsonData["titleEmptyProduct"][this.lang]
    }

    get lblProductImage() {
        return this.jsonData["lblProductImage"][this.lang]
    }

    get lblProductImageMessage() {
        return this.jsonData["lblProductImageMessage"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntProp() {
        return this.jsonData["hntProp"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }

    get lblProductname() {
        return this.jsonData["lblProductname"][this.lang]
    }

    get hntProductname() {
        return this.jsonData["hntProductname"][this.lang]
    }

    get lblProductPublicationStart() {
        return this.jsonData["lblProductPublicationStart"][this.lang]
    }

    get hntProductPublicationStart() {
        return this.jsonData["hntProductPublicationStart"][this.lang]
    }

    get lblProductPublicationEnd() {
        return this.jsonData["lblProductPublicationEnd"][this.lang]
    }

    get hntProductPublicationEnd() {
        return this.jsonData["hntProductPublicationEnd"][this.lang]
    }

    get lblDescriptionDetail() {
        return this.jsonData["lblDescriptionDetail"][this.lang]
    }

    get hntDescriptionDetail() {
        return this.jsonData["hntDescriptionDetail"][this.lang]
    }

    get lblDescriptionShort() {
        return this.jsonData["lblDescriptionShort"][this.lang]
    }

    get hntDescriptionShort() {
        return this.jsonData["hntDescriptionShort"][this.lang]
    }

    get lblTabCategories() {
        return this.jsonData["lblTabCategories"][this.lang]
    }

    get lblTabMultimedia() {
        return this.jsonData["lblTabMultimedia"][this.lang]
    }

    get lblTabKeywords() {
        return this.jsonData["lblTabKeywords"][this.lang]
    }

    get lblCategoriesDescription() {
        return this.jsonData["lblCategoriesDescription"][this.lang]
    }

    get lblMultimediaDescription() {
        return this.jsonData["lblMultimediaDescription"][this.lang]
    }

    get categoryWhitelist() {
        return this.jsonData["categoryWhitelist"]
    }

    get multimediaLimit() {
        return this.jsonData["multimediaLimit"]
    }
}

class TrademarksPageConfigurationImpl extends BrandingData implements TrademarksPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _btnAddTrademark!: LocalizedString;
    _sctTrademark!: TrademarkSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get btnAddTrademark() {
        return this.jsonData["btnAddTrademark"][this.lang]
    }

    get sctTrademark() {
        if (!this._sctTrademark) {
            this._sctTrademark = new TrademarkSectionConfigurationImpl(this.jsonData["sctTrademark"], this.lang)
            this.subBrandings.push(this._sctTrademark as any as BrandingData)
        }
        return this._sctTrademark
    }
}

class TrademarkSectionConfigurationImpl extends BrandingData implements TrademarkSectionConfiguration {
    _titleEmptyTrademark!: LocalizedString;
    _lblTrademarkImage!: LocalizedString;
    _lblTrademarkImageMessage!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntProp!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;
    _lblTrademarkname!: LocalizedString;
    _hntTrademarkname!: LocalizedString;
    _lblDescriptionDetail!: LocalizedString;
    _hntDescriptionDetail!: LocalizedString;
    _lblDescriptionShort!: LocalizedString;
    _hntDescriptionShort!: LocalizedString;
    _lblTabCategories!: LocalizedString;
    _lblTabMultimedia!: LocalizedString;
    _lblTabKeywords!: LocalizedString;
    _lblCategoriesDescription!: LocalizedString;
    _lblMultimediaDescription!: LocalizedString;
    _categoryWhitelist!: string[];
    _multimediaLimit!: number;

    get titleEmptyTrademark() {
        return this.jsonData["titleEmptyTrademark"][this.lang]
    }

    get lblTrademarkImage() {
        return this.jsonData["lblTrademarkImage"][this.lang]
    }

    get lblTrademarkImageMessage() {
        return this.jsonData["lblTrademarkImageMessage"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntProp() {
        return this.jsonData["hntProp"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }

    get lblTrademarkname() {
        return this.jsonData["lblTrademarkname"][this.lang]
    }

    get hntTrademarkname() {
        return this.jsonData["hntTrademarkname"][this.lang]
    }

    get lblDescriptionDetail() {
        return this.jsonData["lblDescriptionDetail"][this.lang]
    }

    get hntDescriptionDetail() {
        return this.jsonData["hntDescriptionDetail"][this.lang]
    }

    get lblDescriptionShort() {
        return this.jsonData["lblDescriptionShort"][this.lang]
    }

    get hntDescriptionShort() {
        return this.jsonData["hntDescriptionShort"][this.lang]
    }

    get lblTabCategories() {
        return this.jsonData["lblTabCategories"][this.lang]
    }

    get lblTabMultimedia() {
        return this.jsonData["lblTabMultimedia"][this.lang]
    }

    get lblTabKeywords() {
        return this.jsonData["lblTabKeywords"][this.lang]
    }

    get lblCategoriesDescription() {
        return this.jsonData["lblCategoriesDescription"][this.lang]
    }

    get lblMultimediaDescription() {
        return this.jsonData["lblMultimediaDescription"][this.lang]
    }

    get categoryWhitelist() {
        return this.jsonData["categoryWhitelist"]
    }

    get multimediaLimit() {
        return this.jsonData["multimediaLimit"]
    }
}

class TeamPageConfigurationImpl extends BrandingData implements TeamPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _btnAddSPerson!: LocalizedString;
    _sctPerson!: PersonSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get btnAddSPerson() {
        return this.jsonData["btnAddSPerson"][this.lang]
    }

    get sctPerson() {
        if (!this._sctPerson) {
            this._sctPerson = new PersonSectionConfigurationImpl(this.jsonData["sctPerson"], this.lang)
            this.subBrandings.push(this._sctPerson as any as BrandingData)
        }
        return this._sctPerson
    }
}

class PersonSectionConfigurationImpl extends BrandingData implements PersonSectionConfiguration {
    _titleEmptyPerson!: LocalizedString;
    _lblProfileImage!: LocalizedString;
    _lblProfileImageMessage!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntProp!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;
    _lblTitleTeam!: LocalizedString;
    _hntTitleTeam!: LocalizedString;
    _lblLastName!: LocalizedString;
    _hntLastName!: LocalizedString;
    _lblFirstName!: LocalizedString;
    _hntFirstName!: LocalizedString;
    _lblRole!: LocalizedString;
    _hntRole!: LocalizedString;
    _lblPosition!: LocalizedString;
    _hntPosition!: LocalizedString;
    _lblCompany!: LocalizedString;
    _hntCompany!: LocalizedString;
    _lblPhone!: LocalizedString;
    _hntPhone!: LocalizedString;
    _lblEmail!: LocalizedString;
    _hntEmail!: LocalizedString;
    _lblDescriptionDetail!: LocalizedString;
    _hntDescriptionDetail!: LocalizedString;
    _lblDescriptionShort!: LocalizedString;
    _hntDescriptionShort!: LocalizedString;

    get titleEmptyPerson() {
        return this.jsonData["titleEmptyPerson"][this.lang]
    }

    get lblProfileImage() {
        return this.jsonData["lblProfileImage"][this.lang]
    }

    get lblProfileImageMessage() {
        return this.jsonData["lblProfileImageMessage"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntProp() {
        return this.jsonData["hntProp"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }

    get lblTitleTeam() {
        return this.jsonData["lblTitleTeam"][this.lang]
    }

    get hntTitleTeam() {
        return this.jsonData["hntTitleTeam"][this.lang]
    }

    get lblLastName() {
        return this.jsonData["lblLastName"][this.lang]
    }

    get hntLastName() {
        return this.jsonData["hntLastName"][this.lang]
    }

    get lblFirstName() {
        return this.jsonData["lblFirstName"][this.lang]
    }

    get hntFirstName() {
        return this.jsonData["hntFirstName"][this.lang]
    }

    get lblRole() {
        return this.jsonData["lblRole"][this.lang]
    }

    get hntRole() {
        return this.jsonData["hntRole"][this.lang]
    }

    get lblPosition() {
        return this.jsonData["lblPosition"][this.lang]
    }

    get hntPosition() {
        return this.jsonData["hntPosition"][this.lang]
    }

    get lblCompany() {
        return this.jsonData["lblCompany"][this.lang]
    }

    get hntCompany() {
        return this.jsonData["hntCompany"][this.lang]
    }

    get lblPhone() {
        return this.jsonData["lblPhone"][this.lang]
    }

    get hntPhone() {
        return this.jsonData["hntPhone"][this.lang]
    }

    get lblEmail() {
        return this.jsonData["lblEmail"][this.lang]
    }

    get hntEmail() {
        return this.jsonData["hntEmail"][this.lang]
    }

    get lblDescriptionDetail() {
        return this.jsonData["lblDescriptionDetail"][this.lang]
    }

    get hntDescriptionDetail() {
        return this.jsonData["hntDescriptionDetail"][this.lang]
    }

    get lblDescriptionShort() {
        return this.jsonData["lblDescriptionShort"][this.lang]
    }

    get hntDescriptionShort() {
        return this.jsonData["hntDescriptionShort"][this.lang]
    }
}

class MultimediaPageConfigurationImpl extends BrandingData implements MultimediaPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _sctMultimediaImage!: MultimediaImageSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get sctMultimediaImage() {
        if (!this._sctMultimediaImage) {
            this._sctMultimediaImage = new MultimediaImageSectionConfigurationImpl(this.jsonData["sctMultimediaImage"], this.lang)
            this.subBrandings.push(this._sctMultimediaImage as any as BrandingData)
        }
        return this._sctMultimediaImage
    }
}

class MultimediaImageSectionConfigurationImpl extends BrandingData implements MultimediaImageSectionConfiguration {
    _hntDropzoneTitle!: LocalizedString;
    _hntDropzoneProp!: LocalizedString;
    _lblHeaderPosition!: LocalizedString;
    _lblHeaderPreview!: LocalizedString;
    _lblHeaderFile!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntSubtitle!: LocalizedString;

    get hntDropzoneTitle() {
        return this.jsonData["hntDropzoneTitle"][this.lang]
    }

    get hntDropzoneProp() {
        return this.jsonData["hntDropzoneProp"][this.lang]
    }

    get lblHeaderPosition() {
        return this.jsonData["lblHeaderPosition"][this.lang]
    }

    get lblHeaderPreview() {
        return this.jsonData["lblHeaderPreview"][this.lang]
    }

    get lblHeaderFile() {
        return this.jsonData["lblHeaderFile"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntSubtitle() {
        return this.jsonData["hntSubtitle"][this.lang]
    }
}

class NewsPageConfigurationImpl extends BrandingData implements NewsPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _btnAddNews!: LocalizedString;
    _sctNews!: NewsSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get btnAddNews() {
        return this.jsonData["btnAddNews"][this.lang]
    }

    get sctNews() {
        if (!this._sctNews) {
            this._sctNews = new NewsSectionConfigurationImpl(this.jsonData["sctNews"], this.lang)
            this.subBrandings.push(this._sctNews as any as BrandingData)
        }
        return this._sctNews
    }
}

class NewsSectionConfigurationImpl extends BrandingData implements NewsSectionConfiguration {
    _titleEmptyNews!: LocalizedString;
    _lblNewsImage!: LocalizedString;
    _lblNewsImageMessage!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntProp!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;
    _lblNewsTitle!: LocalizedString;
    _hntNewsTitle!: LocalizedString;
    _lblNewsDate!: LocalizedString;
    _hntNewsDate!: LocalizedString;
    _lblDescription!: LocalizedString;
    _hntDescription!: LocalizedString;
    _lblSubtitle!: LocalizedString;
    _hntSubtitle!: LocalizedString;
    _lblTabCategories!: LocalizedString;
    _lblTabMultimedia!: LocalizedString;
    _lblTabKeywords!: LocalizedString;
    _lblCategoriesDescription!: LocalizedString;
    _lblMultimediaDescription!: LocalizedString;
    _categoryWhitelist!: string[];
    _multimediaLimit!: number;

    get titleEmptyNews() {
        return this.jsonData["titleEmptyNews"][this.lang]
    }

    get lblNewsImage() {
        return this.jsonData["lblNewsImage"][this.lang]
    }

    get lblNewsImageMessage() {
        return this.jsonData["lblNewsImageMessage"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntProp() {
        return this.jsonData["hntProp"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }

    get lblNewsTitle() {
        return this.jsonData["lblNewsTitle"][this.lang]
    }

    get hntNewsTitle() {
        return this.jsonData["hntNewsTitle"][this.lang]
    }

    get lblNewsDate() {
        return this.jsonData["lblNewsDate"][this.lang]
    }

    get hntNewsDate() {
        return this.jsonData["hntNewsDate"][this.lang]
    }

    get lblDescription() {
        return this.jsonData["lblDescription"][this.lang]
    }

    get hntDescription() {
        return this.jsonData["hntDescription"][this.lang]
    }

    get lblSubtitle() {
        return this.jsonData["lblSubtitle"][this.lang]
    }

    get hntSubtitle() {
        return this.jsonData["hntSubtitle"][this.lang]
    }

    get lblTabCategories() {
        return this.jsonData["lblTabCategories"][this.lang]
    }

    get lblTabMultimedia() {
        return this.jsonData["lblTabMultimedia"][this.lang]
    }

    get lblTabKeywords() {
        return this.jsonData["lblTabKeywords"][this.lang]
    }

    get lblCategoriesDescription() {
        return this.jsonData["lblCategoriesDescription"][this.lang]
    }

    get lblMultimediaDescription() {
        return this.jsonData["lblMultimediaDescription"][this.lang]
    }

    get categoryWhitelist() {
        return this.jsonData["categoryWhitelist"]
    }

    get multimediaLimit() {
        return this.jsonData["multimediaLimit"]
    }
}

class EventDatesPageConfigurationImpl extends BrandingData implements EventDatesPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _btnAddEventDate!: LocalizedString;
    _sctEventDates!: EventDatesSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get btnAddEventDate() {
        return this.jsonData["btnAddEventDate"][this.lang]
    }

    get sctEventDates() {
        if (!this._sctEventDates) {
            this._sctEventDates = new EventDatesSectionConfigurationImpl(this.jsonData["sctEventDates"], this.lang)
            this.subBrandings.push(this._sctEventDates as any as BrandingData)
        }
        return this._sctEventDates
    }
}

class EventDatesSectionConfigurationImpl extends BrandingData implements EventDatesSectionConfiguration {
    _titleEmptyEventDate!: LocalizedString;
    _lblEventDateImage!: LocalizedString;
    _lblEventDateImageMessage!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntProp!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;
    _lblEventdateTitle!: LocalizedString;
    _hntEventdateTitle!: LocalizedString;
    _lblDate!: LocalizedString;
    _hntDate!: LocalizedString;
    _lblTime!: LocalizedString;
    _hntTime!: LocalizedString;
    _lblLocation!: LocalizedString;
    _hntLocation!: LocalizedString;
    _lblEventType!: LocalizedString;
    _hntEventType!: LocalizedString;
    _lblPartEventSeries!: LocalizedString;
    _hntPartEventSeries!: LocalizedString;
    _lblListenon!: LocalizedString;
    _lblListenonTRUE!: LocalizedString;
    _lblListenonFALSE!: LocalizedString;
    _hntListenon!: LocalizedString;
    _lblBreakoutRoom!: LocalizedString;
    _lblDescription!: LocalizedString;
    _hntDescription!: LocalizedString;
    _lblSubtitle!: LocalizedString;
    _hntSubtitle!: LocalizedString;
    _lblTabSpeakers!: LocalizedString;
    _lblTabMultimedia!: LocalizedString;
    _lblTabKeywords!: LocalizedString;
    _lblTabPrerecorded!: LocalizedString;
    _lblPrerecordedDescription!: LocalizedString;
    _lblSpeakersDescription!: LocalizedString;
    _lblMultimediaDescription!: LocalizedString;
    _hntDropzoneTitlePrerecorded!: LocalizedString;
    _hntDropzonePropPrerecorded!: LocalizedString;
    _multimediaLimit!: number;

    get titleEmptyEventDate() {
        return this.jsonData["titleEmptyEventDate"][this.lang]
    }

    get lblEventDateImage() {
        return this.jsonData["lblEventDateImage"][this.lang]
    }

    get lblEventDateImageMessage() {
        return this.jsonData["lblEventDateImageMessage"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntProp() {
        return this.jsonData["hntProp"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }

    get lblEventdateTitle() {
        return this.jsonData["lblEventdateTitle"][this.lang]
    }

    get hntEventdateTitle() {
        return this.jsonData["hntEventdateTitle"][this.lang]
    }

    get lblDate() {
        return this.jsonData["lblDate"][this.lang]
    }

    get hntDate() {
        return this.jsonData["hntDate"][this.lang]
    }

    get lblTime() {
        return this.jsonData["lblTime"][this.lang]
    }

    get hntTime() {
        return this.jsonData["hntTime"][this.lang]
    }

    get lblLocation() {
        return this.jsonData["lblLocation"][this.lang]
    }

    get hntLocation() {
        return this.jsonData["hntLocation"][this.lang]
    }

    get lblEventType() {
        return this.jsonData["lblEventType"][this.lang]
    }

    get hntEventType() {
        return this.jsonData["hntEventType"][this.lang]
    }

    get lblPartEventSeries() {
        return this.jsonData["lblPartEventSeries"][this.lang]
    }

    get hntPartEventSeries() {
        return this.jsonData["hntPartEventSeries"][this.lang]
    }

    get lblListenon() {
        return this.jsonData["lblListenon"][this.lang]
    }

    get lblListenonTRUE() {
        return this.jsonData["lblListenonTRUE"][this.lang]
    }

    get lblListenonFALSE() {
        return this.jsonData["lblListenonFALSE"][this.lang]
    }

    get hntListenon() {
        return this.jsonData["hntListenon"][this.lang]
    }

    get lblBreakoutRoom() {
        return this.jsonData["lblBreakoutRoom"][this.lang]
    }

    get lblDescription() {
        return this.jsonData["lblDescription"][this.lang]
    }

    get hntDescription() {
        return this.jsonData["hntDescription"][this.lang]
    }

    get lblSubtitle() {
        return this.jsonData["lblSubtitle"][this.lang]
    }

    get hntSubtitle() {
        return this.jsonData["hntSubtitle"][this.lang]
    }

    get lblTabSpeakers() {
        return this.jsonData["lblTabSpeakers"][this.lang]
    }

    get lblTabMultimedia() {
        return this.jsonData["lblTabMultimedia"][this.lang]
    }

    get lblTabKeywords() {
        return this.jsonData["lblTabKeywords"][this.lang]
    }

    get lblTabPrerecorded() {
        return this.jsonData["lblTabPrerecorded"][this.lang]
    }

    get lblPrerecordedDescription() {
        return this.jsonData["lblPrerecordedDescription"][this.lang]
    }

    get lblSpeakersDescription() {
        return this.jsonData["lblSpeakersDescription"][this.lang]
    }

    get lblMultimediaDescription() {
        return this.jsonData["lblMultimediaDescription"][this.lang]
    }

    get hntDropzoneTitlePrerecorded() {
        return this.jsonData["hntDropzoneTitlePrerecorded"][this.lang]
    }

    get hntDropzonePropPrerecorded() {
        return this.jsonData["hntDropzonePropPrerecorded"][this.lang]
    }

    get multimediaLimit() {
        return this.jsonData["multimediaLimit"]
    }
}

class ShowroomsPageConfigurationImpl extends BrandingData implements ShowroomsPageConfiguration {
    _pageTitlePublic!: LocalizedString;
    _pageDescriptionPublic!: LocalizedString;
    _pageTitlePrivate!: LocalizedString;
    _pageDescriptionPrivate!: LocalizedString;
    _btnAddShowrooms!: LocalizedString;
    _sctShowrooms!: ShowroomsSectionConfiguration;

    get pageTitlePublic() {
        return this.jsonData["pageTitlePublic"][this.lang]
    }

    get pageDescriptionPublic() {
        return this.jsonData["pageDescriptionPublic"][this.lang]
    }

    get pageTitlePrivate() {
        return this.jsonData["pageTitlePrivate"][this.lang]
    }

    get pageDescriptionPrivate() {
        return this.jsonData["pageDescriptionPrivate"][this.lang]
    }

    get btnAddShowrooms() {
        return this.jsonData["btnAddShowrooms"][this.lang]
    }

    get sctShowrooms() {
        if (!this._sctShowrooms) {
            this._sctShowrooms = new ShowroomsSectionConfigurationImpl(this.jsonData["sctShowrooms"], this.lang)
            this.subBrandings.push(this._sctShowrooms as any as BrandingData)
        }
        return this._sctShowrooms
    }
}

class ShowroomsSectionConfigurationImpl extends BrandingData implements ShowroomsSectionConfiguration {
    _titleEmptyShowrooms!: LocalizedString;
    _lblShowroomsImage!: LocalizedString;
    _lblShowroomsImageMessage!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntProp!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;
    _lblShowroomstitle!: LocalizedString;
    _hntShowroomstitle!: LocalizedString;
    _lblDescriptionShort!: LocalizedString;
    _hntDescriptionShort!: LocalizedString;
    _lblAccessMethod!: LocalizedString;
    _hntAccessMethod!: LocalizedString;
    _lblOptionAccessMethod1!: LocalizedString;
    _lblOptionAccessMethod2!: LocalizedString;

    get titleEmptyShowrooms() {
        return this.jsonData["titleEmptyShowrooms"][this.lang]
    }

    get lblShowroomsImage() {
        return this.jsonData["lblShowroomsImage"][this.lang]
    }

    get lblShowroomsImageMessage() {
        return this.jsonData["lblShowroomsImageMessage"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntProp() {
        return this.jsonData["hntProp"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }

    get lblShowroomstitle() {
        return this.jsonData["lblShowroomstitle"][this.lang]
    }

    get hntShowroomstitle() {
        return this.jsonData["hntShowroomstitle"][this.lang]
    }

    get lblDescriptionShort() {
        return this.jsonData["lblDescriptionShort"][this.lang]
    }

    get hntDescriptionShort() {
        return this.jsonData["hntDescriptionShort"][this.lang]
    }

    get lblAccessMethod() {
        return this.jsonData["lblAccessMethod"][this.lang]
    }

    get hntAccessMethod() {
        return this.jsonData["hntAccessMethod"][this.lang]
    }

    get lblOptionAccessMethod1() {
        return this.jsonData["lblOptionAccessMethod1"][this.lang]
    }

    get lblOptionAccessMethod2() {
        return this.jsonData["lblOptionAccessMethod2"][this.lang]
    }
}

class CouponsPageConfigurationImpl extends BrandingData implements CouponsPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _btnAddCoupons!: LocalizedString;
    _sctCoupons!: CouponsSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get btnAddCoupons() {
        return this.jsonData["btnAddCoupons"][this.lang]
    }

    get sctCoupons() {
        if (!this._sctCoupons) {
            this._sctCoupons = new CouponsSectionConfigurationImpl(this.jsonData["sctCoupons"], this.lang)
            this.subBrandings.push(this._sctCoupons as any as BrandingData)
        }
        return this._sctCoupons
    }
}

class CouponsSectionConfigurationImpl extends BrandingData implements CouponsSectionConfiguration {
    _nameEmptyCoupons!: LocalizedString;
    _lblCouponsImage!: LocalizedString;
    _lblCouponsImageMessage!: LocalizedString;
    _hntPlaceholderTitle!: LocalizedString;
    _hntPlaceholderProp!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;
    _lblCouponsName!: LocalizedString;
    _hntCouponsName!: LocalizedString;
    _lblURL!: LocalizedString;
    _hntURL!: LocalizedString;
    _lblCouponsValidStart!: LocalizedString;
    _hntCouponsValidStart!: LocalizedString;
    _lblCouponsValidEnd!: LocalizedString;
    _hntCouponsValidEnd!: LocalizedString;
    _lblDescriptionTitle!: LocalizedString;
    _hntDescriptionTitle!: LocalizedString;
    _lblDescriptionLong!: LocalizedString;
    _hntDescriptionLong!: LocalizedString;
    _lblDescriptionShort!: LocalizedString;
    _hntDescriptionShort!: LocalizedString;
    _dateCouponsValidStart!: string;
    _dateCouponsValidEnd!: string;

    get nameEmptyCoupons() {
        return this.jsonData["nameEmptyCoupons"][this.lang]
    }

    get lblCouponsImage() {
        return this.jsonData["lblCouponsImage"][this.lang]
    }

    get lblCouponsImageMessage() {
        return this.jsonData["lblCouponsImageMessage"][this.lang]
    }

    get hntPlaceholderTitle() {
        return this.jsonData["hntPlaceholderTitle"][this.lang]
    }

    get hntPlaceholderProp() {
        return this.jsonData["hntPlaceholderProp"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }

    get lblCouponsName() {
        return this.jsonData["lblCouponsName"][this.lang]
    }

    get hntCouponsName() {
        return this.jsonData["hntCouponsName"][this.lang]
    }

    get lblURL() {
        return this.jsonData["lblURL"][this.lang]
    }

    get hntURL() {
        return this.jsonData["hntURL"][this.lang]
    }

    get lblCouponsValidStart() {
        return this.jsonData["lblCouponsValidStart"][this.lang]
    }

    get hntCouponsValidStart() {
        return this.jsonData["hntCouponsValidStart"][this.lang]
    }

    get lblCouponsValidEnd() {
        return this.jsonData["lblCouponsValidEnd"][this.lang]
    }

    get hntCouponsValidEnd() {
        return this.jsonData["hntCouponsValidEnd"][this.lang]
    }

    get lblDescriptionTitle() {
        return this.jsonData["lblDescriptionTitle"][this.lang]
    }

    get hntDescriptionTitle() {
        return this.jsonData["hntDescriptionTitle"][this.lang]
    }

    get lblDescriptionLong() {
        return this.jsonData["lblDescriptionLong"][this.lang]
    }

    get hntDescriptionLong() {
        return this.jsonData["hntDescriptionLong"][this.lang]
    }

    get lblDescriptionShort() {
        return this.jsonData["lblDescriptionShort"][this.lang]
    }

    get hntDescriptionShort() {
        return this.jsonData["hntDescriptionShort"][this.lang]
    }

    get dateCouponsValidStart() {
        return this.jsonData["dateCouponsValidStart"]
    }

    get dateCouponsValidEnd() {
        return this.jsonData["dateCouponsValidEnd"]
    }
}

class JobsPageConfigurationImpl extends BrandingData implements JobsPageConfiguration {
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _btnAddJobs!: LocalizedString;
    _sctJobs!: JobsSectionConfiguration;

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get btnAddJobs() {
        return this.jsonData["btnAddJobs"][this.lang]
    }

    get sctJobs() {
        if (!this._sctJobs) {
            this._sctJobs = new JobsSectionConfigurationImpl(this.jsonData["sctJobs"], this.lang)
            this.subBrandings.push(this._sctJobs as any as BrandingData)
        }
        return this._sctJobs
    }
}

class JobsSectionConfigurationImpl extends BrandingData implements JobsSectionConfiguration {
    _labelJobs!: LocalizedString;
    _titleEmptyJobs!: LocalizedString;
    _hntTitleJob!: LocalizedString;
    _lblJobsImage!: LocalizedString;
    _lblJobsImageMessage!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntProp!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;
    _lblStartDisplayinOnDate!: LocalizedString;
    _hntStartDisplayinOnDate!: LocalizedString;
    _lblEndDisplayinOnDate!: LocalizedString;
    _hntEndDisplayinOnDate!: LocalizedString;
    _lblLastUpdatedDate!: LocalizedString;
    _hntLastUpdatedDate!: LocalizedString;
    _lblJobStartDate!: LocalizedString;
    _hntJobStartDate!: LocalizedString;
    _lblJobLocation!: LocalizedString;
    _hntJobLocation!: LocalizedString;
    _lblEmploymentType!: LocalizedString;
    _hntEmploymentType!: LocalizedString;
    _lblEmploymentLevel!: LocalizedString;
    _hntEmploymentLevel!: LocalizedString;
    _lblJobDescription!: LocalizedString;
    _hntJobDescription!: LocalizedString;
    _lblJobReqirements!: LocalizedString;
    _hntJobReqirements!: LocalizedString;
    _lblTabCategories!: LocalizedString;
    _lblTabContacts!: LocalizedString;
    _lblTabMultimedia!: LocalizedString;
    _lblCategoriesDescription!: LocalizedString;
    _lblContactsDescription!: LocalizedString;
    _lblMultimediaDescription!: LocalizedString;
    _lblHomeoffice!: LocalizedString;
    _lblHomeofficeNA!: LocalizedString;
    _lblHomeofficeNO!: LocalizedString;
    _lblHomeofficeYES!: LocalizedString;
    _multimediaLimit!: number;
    _categoryWhitelist!: string[];
    _lblJobBackgroundImage!: LocalizedString;
    _btnDeleteJobBackgroundImg!: LocalizedString;
    _lblJobsBackgroundImgMessage!: LocalizedString;

    get labelJobs() {
        return this.jsonData["labelJobs"][this.lang]
    }

    get titleEmptyJobs() {
        return this.jsonData["titleEmptyJobs"][this.lang]
    }

    get hntTitleJob() {
        return this.jsonData["hntTitleJob"][this.lang]
    }

    get lblJobsImage() {
        return this.jsonData["lblJobsImage"][this.lang]
    }

    get lblJobsImageMessage() {
        return this.jsonData["lblJobsImageMessage"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntProp() {
        return this.jsonData["hntProp"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }

    get lblStartDisplayinOnDate() {
        return this.jsonData["lblStartDisplayinOnDate"][this.lang]
    }

    get hntStartDisplayinOnDate() {
        return this.jsonData["hntStartDisplayinOnDate"][this.lang]
    }

    get lblEndDisplayinOnDate() {
        return this.jsonData["lblEndDisplayinOnDate"][this.lang]
    }

    get hntEndDisplayinOnDate() {
        return this.jsonData["hntEndDisplayinOnDate"][this.lang]
    }

    get lblLastUpdatedDate() {
        return this.jsonData["lblLastUpdatedDate"][this.lang]
    }

    get hntLastUpdatedDate() {
        return this.jsonData["hntLastUpdatedDate"][this.lang]
    }

    get lblJobStartDate() {
        return this.jsonData["lblJobStartDate"][this.lang]
    }

    get hntJobStartDate() {
        return this.jsonData["hntJobStartDate"][this.lang]
    }

    get lblJobLocation() {
        return this.jsonData["lblJobLocation"][this.lang]
    }

    get hntJobLocation() {
        return this.jsonData["hntJobLocation"][this.lang]
    }

    get lblEmploymentType() {
        return this.jsonData["lblEmploymentType"][this.lang]
    }

    get hntEmploymentType() {
        return this.jsonData["hntEmploymentType"][this.lang]
    }

    get lblEmploymentLevel() {
        return this.jsonData["lblEmploymentLevel"][this.lang]
    }

    get hntEmploymentLevel() {
        return this.jsonData["hntEmploymentLevel"][this.lang]
    }

    get lblJobDescription() {
        return this.jsonData["lblJobDescription"][this.lang]
    }

    get hntJobDescription() {
        return this.jsonData["hntJobDescription"][this.lang]
    }

    get lblJobReqirements() {
        return this.jsonData["lblJobReqirements"][this.lang]
    }

    get hntJobReqirements() {
        return this.jsonData["hntJobReqirements"][this.lang]
    }

    get lblTabCategories() {
        return this.jsonData["lblTabCategories"][this.lang]
    }

    get lblTabContacts() {
        return this.jsonData["lblTabContacts"][this.lang]
    }

    get lblTabMultimedia() {
        return this.jsonData["lblTabMultimedia"][this.lang]
    }

    get lblCategoriesDescription() {
        return this.jsonData["lblCategoriesDescription"][this.lang]
    }

    get lblContactsDescription() {
        return this.jsonData["lblContactsDescription"][this.lang]
    }

    get lblMultimediaDescription() {
        return this.jsonData["lblMultimediaDescription"][this.lang]
    }

    get lblHomeoffice() {
        return this.jsonData["lblHomeoffice"][this.lang]
    }

    get lblHomeofficeNA() {
        return this.jsonData["lblHomeofficeNA"][this.lang]
    }

    get lblHomeofficeNO() {
        return this.jsonData["lblHomeofficeNO"][this.lang]
    }

    get lblHomeofficeYES() {
        return this.jsonData["lblHomeofficeYES"][this.lang]
    }

    get multimediaLimit() {
        return this.jsonData["multimediaLimit"]
    }

    get categoryWhitelist() {
        return this.jsonData["categoryWhitelist"]
    }

    get lblJobBackgroundImage() {
        return this.jsonData["lblJobBackgroundImage"][this.lang]
    }

    get btnDeleteJobBackgroundImg() {
        return this.jsonData["btnDeleteJobBackgroundImg"][this.lang]
    }

    get lblJobsBackgroundImgMessage() {
        return this.jsonData["lblJobsBackgroundImgMessage"][this.lang]
    }
}

class PrintPageConfigurationImpl extends BrandingData implements PrintPageConfiguration {
    _deadLine!: string;
    _pageTitle!: LocalizedString;
    _pageDescription!: LocalizedString;
    _printDeadline!: LocalizedString;
    _lblCopyData!: LocalizedString;
    _sctLogoBackgroundImage!: LogoBackgroundImageSectionConfiguration;
    _sctCompanyName!: CompanyNameSectionConfiguration;
    _sctCompanySort!: CompanySortSectionConfiguration;
    _sctAddressPrintCatalogue!: AddressPrintCatalogueSectionConfiguration;
    _sctContactInformation!: ContactInformationSectionConfiguration;
    _sctCompanyDescription!: CompanyDescriptionSectionConfiguration;
    _sctAds!: AdsSectionConfiguration;

    get deadLine() {
        return this.jsonData["deadLine"]
    }

    get pageTitle() {
        return this.jsonData["pageTitle"][this.lang]
    }

    get pageDescription() {
        return this.jsonData["pageDescription"][this.lang]
    }

    get printDeadline() {
        return this.jsonData["printDeadline"][this.lang]
    }

    get lblCopyData() {
        return this.jsonData["lblCopyData"][this.lang]
    }

    get sctLogoBackgroundImage() {
        if (!this._sctLogoBackgroundImage) {
            this._sctLogoBackgroundImage = new LogoBackgroundImageSectionConfigurationImpl(this.jsonData["sctLogoBackgroundImage"], this.lang)
            this.subBrandings.push(this._sctLogoBackgroundImage as any as BrandingData)
        }
        return this._sctLogoBackgroundImage
    }

    get sctCompanyName() {
        if (!this._sctCompanyName) {
            this._sctCompanyName = new CompanyNameSectionConfigurationImpl(this.jsonData["sctCompanyName"], this.lang)
            this.subBrandings.push(this._sctCompanyName as any as BrandingData)
        }
        return this._sctCompanyName
    }

    get sctCompanySort() {
        if (!this._sctCompanySort) {
            this._sctCompanySort = new CompanySortSectionConfigurationImpl(this.jsonData["sctCompanySort"], this.lang)
            this.subBrandings.push(this._sctCompanySort as any as BrandingData)
        }
        return this._sctCompanySort
    }

    get sctAddressPrintCatalogue() {
        if (!this._sctAddressPrintCatalogue) {
            this._sctAddressPrintCatalogue = new AddressPrintCatalogueSectionConfigurationImpl(this.jsonData["sctAddressPrintCatalogue"], this.lang)
            this.subBrandings.push(this._sctAddressPrintCatalogue as any as BrandingData)
        }
        return this._sctAddressPrintCatalogue
    }

    get sctContactInformation() {
        if (!this._sctContactInformation) {
            this._sctContactInformation = new ContactInformationSectionConfigurationImpl(this.jsonData["sctContactInformation"], this.lang)
            this.subBrandings.push(this._sctContactInformation as any as BrandingData)
        }
        return this._sctContactInformation
    }

    get sctCompanyDescription() {
        if (!this._sctCompanyDescription) {
            this._sctCompanyDescription = new CompanyDescriptionSectionConfigurationImpl(this.jsonData["sctCompanyDescription"], this.lang)
            this.subBrandings.push(this._sctCompanyDescription as any as BrandingData)
        }
        return this._sctCompanyDescription
    }

    get sctAds() {
        if (!this._sctAds) {
            this._sctAds = new AdsSectionConfigurationImpl(this.jsonData["sctAds"], this.lang)
            this.subBrandings.push(this._sctAds as any as BrandingData)
        }
        return this._sctAds
    }
}

class LogoBackgroundImageSectionConfigurationImpl extends BrandingData implements LogoBackgroundImageSectionConfiguration {
    _lblSectionTitleLogoBackground!: LocalizedString;
    _lblCompanyLogo!: LocalizedString;
    _lblCompanyLogoImageMessage!: LocalizedString;
    _hntTitle!: LocalizedString;
    _hntProp!: LocalizedString;
    _btnBrowseFiles!: LocalizedString;
    _btnDeleteImg!: LocalizedString;

    get lblSectionTitleLogoBackground() {
        return this.jsonData["lblSectionTitleLogoBackground"][this.lang]
    }

    get lblCompanyLogo() {
        return this.jsonData["lblCompanyLogo"][this.lang]
    }

    get lblCompanyLogoImageMessage() {
        return this.jsonData["lblCompanyLogoImageMessage"][this.lang]
    }

    get hntTitle() {
        return this.jsonData["hntTitle"][this.lang]
    }

    get hntProp() {
        return this.jsonData["hntProp"][this.lang]
    }

    get btnBrowseFiles() {
        return this.jsonData["btnBrowseFiles"][this.lang]
    }

    get btnDeleteImg() {
        return this.jsonData["btnDeleteImg"][this.lang]
    }
}

class CompanyNameSectionConfigurationImpl extends BrandingData implements CompanyNameSectionConfiguration {
    _lblSectionCompanyName!: LocalizedString;
    _lblCompanyName!: LocalizedString;
    _hntCompanyName!: LocalizedString;

    get lblSectionCompanyName() {
        return this.jsonData["lblSectionCompanyName"][this.lang]
    }

    get lblCompanyName() {
        return this.jsonData["lblCompanyName"][this.lang]
    }

    get hntCompanyName() {
        return this.jsonData["hntCompanyName"][this.lang]
    }
}

class CompanySortSectionConfigurationImpl extends BrandingData implements CompanySortSectionConfiguration {
    _lblSectionCompanySort!: LocalizedString;
    _lblCompanySort!: LocalizedString;

    get lblSectionCompanySort() {
        return this.jsonData["lblSectionCompanySort"][this.lang]
    }

    get lblCompanySort() {
        return this.jsonData["lblCompanySort"][this.lang]
    }
}

class AddressPrintCatalogueSectionConfigurationImpl extends BrandingData implements AddressPrintCatalogueSectionConfiguration {
    _lblSectionAddressInformation!: LocalizedString;
    _lblAddress1!: LocalizedString;
    _hntAddress1!: LocalizedString;
    _lblAddress2!: LocalizedString;
    _hntAddress2!: LocalizedString;
    _lblAddress3!: LocalizedString;
    _hntAddress3!: LocalizedString;
    _lblZipCode!: LocalizedString;
    _hntZipCode!: LocalizedString;
    _lblCity!: LocalizedString;
    _hntCity!: LocalizedString;
    _lblCountry!: LocalizedString;
    _hntCountry!: LocalizedString;

    get lblSectionAddressInformation() {
        return this.jsonData["lblSectionAddressInformation"][this.lang]
    }

    get lblAddress1() {
        return this.jsonData["lblAddress1"][this.lang]
    }

    get hntAddress1() {
        return this.jsonData["hntAddress1"][this.lang]
    }

    get lblAddress2() {
        return this.jsonData["lblAddress2"][this.lang]
    }

    get hntAddress2() {
        return this.jsonData["hntAddress2"][this.lang]
    }

    get lblAddress3() {
        return this.jsonData["lblAddress3"][this.lang]
    }

    get hntAddress3() {
        return this.jsonData["hntAddress3"][this.lang]
    }

    get lblZipCode() {
        return this.jsonData["lblZipCode"][this.lang]
    }

    get hntZipCode() {
        return this.jsonData["hntZipCode"][this.lang]
    }

    get lblCity() {
        return this.jsonData["lblCity"][this.lang]
    }

    get hntCity() {
        return this.jsonData["hntCity"][this.lang]
    }

    get lblCountry() {
        return this.jsonData["lblCountry"][this.lang]
    }

    get hntCountry() {
        return this.jsonData["hntCountry"][this.lang]
    }
}

class ContactInformationSectionConfigurationImpl extends BrandingData implements ContactInformationSectionConfiguration {
    _lblSectionContactInfo!: LocalizedString;
    _lblPhone!: LocalizedString;
    _hntPhone!: LocalizedString;
    _lblMobile!: LocalizedString;
    _hntMobile!: LocalizedString;
    _lblFax!: LocalizedString;
    _hntFax!: LocalizedString;
    _lblEmail!: LocalizedString;
    _hntEmail!: LocalizedString;
    _lblWebsite!: LocalizedString;
    _hntWebsite!: LocalizedString;

    get lblSectionContactInfo() {
        return this.jsonData["lblSectionContactInfo"][this.lang]
    }

    get lblPhone() {
        return this.jsonData["lblPhone"][this.lang]
    }

    get hntPhone() {
        return this.jsonData["hntPhone"][this.lang]
    }

    get lblMobile() {
        return this.jsonData["lblMobile"][this.lang]
    }

    get hntMobile() {
        return this.jsonData["hntMobile"][this.lang]
    }

    get lblFax() {
        return this.jsonData["lblFax"][this.lang]
    }

    get hntFax() {
        return this.jsonData["hntFax"][this.lang]
    }

    get lblEmail() {
        return this.jsonData["lblEmail"][this.lang]
    }

    get hntEmail() {
        return this.jsonData["hntEmail"][this.lang]
    }

    get lblWebsite() {
        return this.jsonData["lblWebsite"][this.lang]
    }

    get hntWebsite() {
        return this.jsonData["hntWebsite"][this.lang]
    }
}

class CompanyDescriptionSectionConfigurationImpl extends BrandingData implements CompanyDescriptionSectionConfiguration {
    _lblSectionCompanyDescription!: LocalizedString;
    _lblCompanyPortrait!: LocalizedString;
    _hntCompanyPortrait!: LocalizedString;

    get lblSectionCompanyDescription() {
        return this.jsonData["lblSectionCompanyDescription"][this.lang]
    }

    get lblCompanyPortrait() {
        return this.jsonData["lblCompanyPortrait"][this.lang]
    }

    get hntCompanyPortrait() {
        return this.jsonData["hntCompanyPortrait"][this.lang]
    }
}

class AdsSectionConfigurationImpl extends BrandingData implements AdsSectionConfiguration {
    _lblAdsSection!: LocalizedString;
    _lblAdsDecription!: LocalizedString;
    _hntDropzoneTitle!: LocalizedString;
    _hntDropzoneProp!: LocalizedString;
    _lblHeaderPosition!: LocalizedString;
    _lblHeaderPreview!: LocalizedString;
    _lblHeaderFile!: LocalizedString;

    get lblAdsSection() {
        return this.jsonData["lblAdsSection"][this.lang]
    }

    get lblAdsDecription() {
        return this.jsonData["lblAdsDecription"][this.lang]
    }

    get hntDropzoneTitle() {
        return this.jsonData["hntDropzoneTitle"][this.lang]
    }

    get hntDropzoneProp() {
        return this.jsonData["hntDropzoneProp"][this.lang]
    }

    get lblHeaderPosition() {
        return this.jsonData["lblHeaderPosition"][this.lang]
    }

    get lblHeaderPreview() {
        return this.jsonData["lblHeaderPreview"][this.lang]
    }

    get lblHeaderFile() {
        return this.jsonData["lblHeaderFile"][this.lang]
    }
}

class EventShopPageConfigurationImpl extends BrandingData implements EventShopPageConfiguration {
    _eventShopUrl!: LocalizedString;

    get eventShopUrl() {
        return this.jsonData["eventShopUrl"][this.lang]
    }
}

class ValidationMessagesConfigurationImpl extends BrandingData implements ValidationMessagesConfiguration {
    _missingCompanyName!: LocalizedString;
    _missingCompanyAddress!: LocalizedString;
    _missingCompanyPostcode!: LocalizedString;
    _missingCompanyCity!: LocalizedString;
    _missingCompanyCountry!: LocalizedString;
    _invalidCompanyEmail!: LocalizedString;
    _invalidCompanyEmail2!: LocalizedString;
    _missingLinkName!: LocalizedString;
    _missingLinkUrl!: LocalizedString;
    _missingProductName!: LocalizedString;
    _invalidProductPublicationEndDate!: LocalizedString;
    _missingTrademarkName!: LocalizedString;
    _missingFirstName!: LocalizedString;
    _missingLastName!: LocalizedString;
    _missingEmail!: LocalizedString;
    _invalidEmail!: LocalizedString;
    _missingRole!: LocalizedString;
    _missingMediaTitle!: LocalizedString;
    _missingNewsTitle!: LocalizedString;
    _missingNewsDate!: LocalizedString;
    _missingEventDateTitle!: LocalizedString;
    _missingShowroomTitle!: LocalizedString;
    _missingCouponName!: LocalizedString;
    _missingCouponValidUrl!: LocalizedString;
    _missingJobTitle!: LocalizedString;
    _missingPrintCompanyName!: LocalizedString;
    _missingPrintAddress!: LocalizedString;
    _missingPrintZipcode!: LocalizedString;
    _missingPrintCity!: LocalizedString;
    _missingPrintCountry!: LocalizedString;
    _missingValidStartDate!: LocalizedString;
    _missingValidEndDate!: LocalizedString;

    get missingCompanyName() {
        return this.jsonData["missingCompanyName"][this.lang]
    }

    get missingCompanyAddress() {
        return this.jsonData["missingCompanyAddress"][this.lang]
    }

    get missingCompanyPostcode() {
        return this.jsonData["missingCompanyPostcode"][this.lang]
    }

    get missingCompanyCity() {
        return this.jsonData["missingCompanyCity"][this.lang]
    }

    get missingCompanyCountry() {
        return this.jsonData["missingCompanyCountry"][this.lang]
    }

    get invalidCompanyEmail() {
        return this.jsonData["invalidCompanyEmail"][this.lang]
    }

    get invalidCompanyEmail2() {
        return this.jsonData["invalidCompanyEmail2"][this.lang]
    }

    get missingLinkName() {
        return this.jsonData["missingLinkName"][this.lang]
    }

    get missingLinkUrl() {
        return this.jsonData["missingLinkUrl"][this.lang]
    }

    get missingProductName() {
        return this.jsonData["missingProductName"][this.lang]
    }

    get invalidProductPublicationEndDate() {
        return this.jsonData["invalidProductPublicationEndDate"][this.lang]
    }

    get missingTrademarkName() {
        return this.jsonData["missingTrademarkName"][this.lang]
    }

    get missingFirstName() {
        return this.jsonData["missingFirstName"][this.lang]
    }

    get missingLastName() {
        return this.jsonData["missingLastName"][this.lang]
    }

    get missingEmail() {
        return this.jsonData["missingEmail"][this.lang]
    }

    get invalidEmail() {
        return this.jsonData["invalidEmail"][this.lang]
    }

    get missingRole() {
        return this.jsonData["missingRole"][this.lang]
    }

    get missingMediaTitle() {
        return this.jsonData["missingMediaTitle"][this.lang]
    }

    get missingNewsTitle() {
        return this.jsonData["missingNewsTitle"][this.lang]
    }

    get missingNewsDate() {
        return this.jsonData["missingNewsDate"][this.lang]
    }

    get missingEventDateTitle() {
        return this.jsonData["missingEventDateTitle"][this.lang]
    }

    get missingShowroomTitle() {
        return this.jsonData["missingShowroomTitle"][this.lang]
    }

    get missingCouponName() {
        return this.jsonData["missingCouponName"][this.lang]
    }

    get missingCouponValidUrl() {
        return this.jsonData["missingCouponValidUrl"][this.lang]
    }

    get missingJobTitle() {
        return this.jsonData["missingJobTitle"][this.lang]
    }

    get missingPrintCompanyName() {
        return this.jsonData["missingPrintCompanyName"][this.lang]
    }

    get missingPrintAddress() {
        return this.jsonData["missingPrintAddress"][this.lang]
    }

    get missingPrintZipcode() {
        return this.jsonData["missingPrintZipcode"][this.lang]
    }

    get missingPrintCity() {
        return this.jsonData["missingPrintCity"][this.lang]
    }

    get missingPrintCountry() {
        return this.jsonData["missingPrintCountry"][this.lang]
    }

    get missingValidStartDate() {
        return this.jsonData["missingValidStartDate"][this.lang]
    }

    get missingValidEndDate() {
        return this.jsonData["missingValidEndDate"][this.lang]
    }
}

class ErrorMessagesConfigurationImpl extends BrandingData implements ErrorMessagesConfiguration {
    _invalidFileType!: LocalizedString;

    get invalidFileType() {
        return this.jsonData["invalidFileType"][this.lang]
    }
}

class CategoriesCountableConfigurationImpl extends BrandingData implements CategoriesCountableConfiguration {
    _categoriesCountableCategories!: string[];
    _jobCountableCategories!: string[];
    _newsCountableCategories!: string[];
    _productCountableCategories!: string[];
    _trademarkCountableCategories!: string[];
    _warningMessage!: LocalizedString;

    get categoriesCountableCategories() {
        return this.jsonData["categoriesCountableCategories"]
    }

    get jobCountableCategories() {
        return this.jsonData["jobCountableCategories"]
    }

    get newsCountableCategories() {
        return this.jsonData["newsCountableCategories"]
    }

    get productCountableCategories() {
        return this.jsonData["productCountableCategories"]
    }

    get trademarkCountableCategories() {
        return this.jsonData["trademarkCountableCategories"]
    }

    get warningMessage() {
        return this.jsonData["warningMessage"][this.lang]
    }
}
